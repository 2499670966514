<script lang="ts" setup>
const localeRoute = useLocaleRoute();
const route = useRoute();
</script>

<template>
  <footer :class="`border-t border-gray-tonal ` + route.meta.footer_class">
    <div class="container py-8">
      <v-row :class="{ borderline: $vuetify.display.mdAndDown }">
        <v-col cols="12" lg="4" class="text-center md:text-left">
          <img src="https://nextpark.pl/img/logos/nextpark_logo.webp" alt="NextPark" loading="lazy" class="inline" width="200" />
          <p class="mt-4">{{ $t("footer.slogan_title") }}</p>
          <p class="my-6">
            {{ $t("footer.slogan_subtitle") }}
          </p>
          <div class="flex items-end">
            <ul class="block">
              <li class="inline-block">
                <a href="https://www.facebook.com/nextpark/" title="Facebook" rel="nofollow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ccc" viewBox="0 0 24 24">
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="inline-block ml-4">
                <a href="https://twitter.com/NextParkPL" title="Twitter" rel="nofollow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ccc" viewBox="0 0 24 24">
                    <path
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="inline-block ml-4">
                <a href="https://www.youtube.com/channel/UCsbWl22rT8Yk88L13eJ-vTQ/" title="YouTube" rel="nofollow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ccc" viewBox="0 0 24 24">
                    <path
                      d="M4.652 0h1.44l.988 3.702.916-3.702h1.454l-1.665 5.505v3.757h-1.431v-3.757l-1.702-5.505zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831v-3.349c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646v-3.179c0-.374.172-.651.529-.651.39 0 .557.269.557.651v3.179zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46v-5.238h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27v-6.831h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04c-.084.043-.167.109-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0-2.814.192-3.146 1.892-3.167 6.367.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zm-12.324 10.686h-1.363v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718c-.223.265-.455.467-.696.605-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="inline-block ml-4">
                <a href="https://www.linkedin.com/company/nextpark" title="LinkedIn" rel="nofollow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ccc" viewBox="0 0 24 24">
                    <path
                      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                    ></path>
                  </svg>
                </a>
              </li>
              <li class="inline-block ml-4">
                <a href="https://www.instagram.com/nextpark.pl/" title="Instagram" rel="nofollow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ccc" viewBox="0 0 24 24">
                    <path
                      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
            <v-spacer></v-spacer>
            <Locale color="gray" :icon="false" />
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-list :aria-label="$t('footer.header_help')" aria-labelledby="label_help" bg-color="transparent" class="md:pt-0" :opened="$vuetify.display.mdAndUp ? ['ListHelp'] : []">
            <v-list-group
              value="ListHelp"
              fluid
              density="compact"
              :collapse-icon="$vuetify.display.mdAndDown ? '$collapse' : null"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  :link="false"
                  :active="false"
                  id="label_help"
                  density="compact"
                  class="font-semibold"
                  v-bind="$vuetify.display.mdAndDown ? props : null"
                >
                  {{ $t("footer.header_help") }}
                </v-list-item>
              </template>
              <v-list-item
                color="primary"
                density="compact"
                class="rounded-3xl"
                role="option"
                v-for="(item, index) in [
                  { title: $t('menu.faq'), route: 'faq' },
                  { title: $t('menu.others_howitworks'), route: 'others-howitworks' },
                  { title: $t('menu.others_howtoselect'), route: 'others-howtoselect' },
                  { title: $t('menu.others_whywe'), route: 'others-whywe' },
                  // { title: 'Parkingi lotniskowe', route: '#' },
                  // { title: 'Parkingi przy dworcach', route: '#' },
                ]"
                :key="index"
                :to="localeRoute({ name: item.route })"
                :id="item.route"
              >
                {{ item.title }}
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>

        <v-col cols="12" md="6" lg="2">
          <v-list :aria-label="$t('footer.header_addons')" aria-labelledby="label_addons" bg-color="transparent" class="md:pt-0" :opened="$vuetify.display.mdAndUp ? ['ListAddons'] : []">
            <v-list-group
              value="ListAddons"
              fluid
              density="compact"
              :collapse-icon="$vuetify.display.mdAndDown ? '$collapse' : null"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  :link="false"
                  :active="false"
                  id="label_services"
                  density="compact"
                  class="font-semibold"
                  v-bind="$vuetify.display.mdAndDown ? props : null"
                >
                  {{ $t("footer.header_addons") }}
                </v-list-item>
              </template>
              <v-list-item
                class="rounded-3xl"
                variant="text"
                color="primary"
                density="compact"
                role="option"
                v-for="(item, index) in [
                  { title: $t('menu.others_extras_carrentals'), route: 'others-extras-carrentals' },
                  { title: $t('menu.others_extras_travelinsurance'), route: 'others-extras-travelinsurance' },
                  { title: $t('menu.others_extras_airclaims'), route: 'others-extras-airclaims' },
                ]"
                :key="index"
                :to="localeRoute({ name: item.route })"
                :id="item.route"
              >
                {{ item.title }}
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" lg="2">
          <v-list :aria-label="$t('footer.header_services')" aria-labelledby="label_services" bg-color="transparent" class="md:pt-0" :opened="$vuetify.display.mdAndUp ? ['ListServices'] : []">
            <v-list-group
              value="ListServices"
              fluid
              density="compact"
              :collapse-icon="$vuetify.display.mdAndDown ? '$collapse' : null"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  :link="false"
                  id="label_services"
                  :active="false"
                  density="compact"
                  class="font-semibold"
                  v-bind="$vuetify.display.mdAndDown ? props : null"
                >
                  {{ $t("footer.header_services") }}
                </v-list-item>
              </template>
              <v-list-item
                class="rounded-3xl"
                color="primary"
                density="compact"
                role="option"
                v-for="(item, index) in [
                  { title: $t('menu.blog'), route: 'blog' },
                  { title: $t('menu.customer'), route: 'customer' },
                ]"
                :key="index"
                :to="localeRoute({ name: item.route })"
                :id="item.route"
              >
                {{ item.title }}
              </v-list-item>
              <v-list-item
                class="rounded-3xl"
                :active="false"
                density="compact"
                role="option"
                v-for="(item, index) in [
                  { title: $t('menu.external_partner'), route: 'https://partner.nextpark.pl/pl/', id: 'label_partner' },
                  { title: $t('menu.external_parkflow'), route: 'https://parkflow.io/pl/', id: 'label_parkflow' }
                ]"
                :key="index"
                :href="item.route"
                :id="item.id"
              >
                {{ item.title }}
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
        <v-col cols="12" md="6" lg="2" class="text-center">
          <h6 class="font-semibold pt-2 mb-4">
            {{ $t("footer.header_contact") }}
          </h6>
          <a href="tel:+48222662439" rel="nofollow" class="hover:underline text-2xl font-bold text-primary" :title="$t('fields.phone')">
            +48 222 662 439
          </a>

          <p class="mb-4">Pon - Pt: &nbsp;&nbsp; 8:00 - 20:00</p>
          <p class="has-text-brand mt-3 is-size-7">
            {{ $t("footer.contact_comment") }}
          </p>
        </v-col>
      </v-row>
      <v-row class="border-t border-gray-tonal pt-6">
        <v-col cols="9">
          ©
          <span class="hidden md:inline">2008 - {{ new Date().getFullYear() }}</span>
          <a href="https://smartpark-solutions.com" rel="nofollow" class="hover:underline text-no-wrap"
          title="SmartPark"
            >SmartPark Solutions Sp. z o.o.</a
          >
          <span class="mx-2 hidden md:inline">·</span>
          <ol class="block md:inline">
            <li class="inline">
              <NuxtLink
                :to="localeRoute({ name: 'others-rules-privacy' })"
                class="hover:underline"
                rel="nofollow"
                :prefetch="false"
              >
                {{ $t("menu.others_rules_privacy") }}
              </NuxtLink>
              <span class="mx-2">·</span>
            </li>
            <li class="inline">
              <NuxtLink
                :to="localeRoute({ name: 'others-rules-terms' })"
                class="hover:underline"
                rel="nofollow"
                :prefetch="false"
              >
                {{ $t("menu.others_rules_terms") }}
              </NuxtLink>
              <!-- <span class="mx-2">·</span> -->
            </li>
            <!-- <li class="inline">
            <NuxtLink :to="localeRoute({ name: 'index' })" class="hover:underline" :prefetch="false">Mapa Witryny</NuxtLink>
          </li> -->
          </ol>
        </v-col>
        <v-col cols="3" class="text-right">
          <ol class="inline">
            <li class="inline-block">
              <img src="/images/payments/visa.png" height="20" alt="VISA" loading="lazy" />
              <!-- <NuxtPicture sizes="md:100vw md:50vw lg:300px" src="@/images/payments/visa.png" alt="VISA" loading="lazy" /> -->
            </li>
            <li class="inline-block ml-6">
              <img src="/images/payments/mastercard.png" height="24" alt="MasterCard" loading="lazy" />
              <!-- <NuxtPicture sizes="md:100vw md:50vw lg:300px" src="/images/payments/mastercard.png" alt="MasterCard" loading="lazy" /> -->
            </li>
            <li class="hidden md:inline-block ml-6">
              <img src="/images/payments/payu.png" height="24" alt="PayU" loading="lazy" />
              <!-- <NuxtPicture sizes="md:100vw md:50vw lg:300px" src="/images/payments/payu.png" alt="PayU" loading="lazy" /> -->
            </li>
          </ol>
        </v-col>
      </v-row>
    </div>
  </footer>
</template>
